<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center my-2">
          <p class="mb-0 mx-2">Főtámogató:</p>
          <img src="@/assets/images/logo_colored.png" alt="Image 1" class="img-fluid">
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center my-2">
          <p class="mb-0 mx-2">Szervező:</p>
          <a href="https://www.ecosim.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/images/ecosim.svg" alt="Image 2" class="img-fluid">
          </a>
        </div>
      </div>
    </div>

    <div class="logos d-flex justify-content-center align-items-center p-5">
      <div class="row">
        <div class="col-6 col-md-2">
          <a href="https://mobilarena.hu/index.html" target="_blank" rel="noreferrer">
            <img src="@/assets/images/mobilarena.png" alt="Image 1" class="img-fluid" />
          </a>
        </div>
        <div class="col-6 col-md-2">
          <a href="https://prohardver.hu/index.html" target="_blank" rel="noreferrer">
            <img src="@/assets/images/prohardver.png" alt="Image 2" class="img-fluid" />
          </a>
        </div>
        <div class="col-6 col-md-2">
          <a href="https://itcafe.hu/index.html" target="_blank" rel="noreferrer">
            <img src="../assets/images/itcafe.png" alt="Image 3" class="img-fluid" />
          </a>
        </div>
        <div class="col-6 col-md-2">
          <a href="https://www.infha.hu/" target="_blank" rel="noreferrer">
            <img src="@/assets/images/infha.png" alt="Image 4" class="img-fluid" />
          </a>
        </div>
        <div class="col-6 col-md-2">
          <a href="https://www.facebook.com/SchonherzVallalatiKapcsolatok/" target="_blank" rel="noreferrer">
            <img src="@/assets/images/svk.png" alt="Image 5" class="img-fluid" />
          </a>
        </div>
        <div class="col-6 col-md-2">
          <a href="https://www.inf.u-szeged.hu/" target="_blank" rel="noreferrer">
            <img src="@/assets/images/szte.png" alt="Image 6" class="img-fluid" />
          </a>
        </div>
      </div>
    </div>

    <div class="bottom mt-3 py-3">
      <div class="container">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <img src="@/assets/images/logo.svg" alt="Logo" class="img-fluid" width="100" height="100">
            <div class="m-4 d-flex">
              <router-link :to="{name: 'contact_'+language}" class="nav-link mx-3"><strong>Kapcsolat</strong></router-link>
              <a href="https://www.otpbank.hu/static/portal/sw/file/Verseny_IT_Hackathon_Adatkezelesi_tajekoztato.pdf" target="_blank" rel="noreferrer"><strong>Adatvédelem</strong></a>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-end">
            <p class="m-3">© Copyright {{ new Date().getFullYear() }} OTP BANK IT</p>
            <a href="https://www.facebook.com/otpbank.hu/" target="_blank" rel="noreferrer"><img src="../assets/images/facebook.png" alt="OTP Bank Facebook" class="img-fluid"></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: ["contents","language"],
};
</script>

<style scoped>

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--otp-main);
  border-radius: 40px 40px 0 0;
}

.bottom p, .bottom a {
  font-size: 0.825rem;
  color: var(--text-primary-inverse);
}
@media screen and (max-width: 768px) {
  .logos{
    padding-bottom: 5rem!important;
  }
}
</style>
